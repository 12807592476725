@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600');

$font-family-sans-serif: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';
$line-height-base: 1;

$shadow: #ececec;

$lightGray: #dbdbdb;

$darkGray: #484848;
$gray-700: $darkGray;
$gray-900: $darkGray;

$warmGray: #7b7b7b;
$gray-600: $warmGray;

$green: #01b07a;
$red: #f36a4e;

$primary: $green;
$secondary: #fff;
$danger: $red;

$label-margin-bottom: 0;
$dropdown-link-hover-color: $darkGray;
$dropdown-link-hover-bg: rgba($green, 0.08);
$input-focus-border-color: $green;

$enable-shadows: true;

$line-height-base: 1.3125; // 21px
// button height should be 40px
$input-btn-padding-x: 0.8125rem; // 13px
$input-btn-padding-y: 0.53125rem; // 8.5px
$btn-line-height: 1.3125rem; // 21px;
$btn-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
$font-size-base: 0.8125rem; // 13px;
$font-weight-normal: 500;

$spacer: 24px;

$nav-link-padding-x: $input-btn-padding-x;
$nav-link-padding-y: $input-btn-padding-y;

$navbar-light-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23484848' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M2 6h24M2 14h24M2 22h24'/%3E%3C/svg%3E");
$navbar-light-toggler-border-color: transparent;
$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;

$navbar-light-color: $green;
$navbar-light-hover-color: darken($green, 15%);

$mark-padding: 0;
$mark-bg: rgba($green, 0.2);
mark,
.mark {
  font-weight: 500;
}

@import 'node_modules/bootstrap/scss/bootstrap';

.nav-link {
  line-height: $btn-line-height;

  &:hover,
  &:focus,
  &.focus {
    text-decoration: underline;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-icon {
  border-radius: 50%;
  padding: 0;
  width: 40px;
  height: 40px;

  &.btn-sm {
    width: 34px;
    height: 34px;
  }

  &.btn-lg {
    width: 46px;
    height: 46px;
  }
}

.btn-secondary {
  &:focus,
  &.focus {
    box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($darkGray, 0.5);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    &:focus {
      box-shadow: $btn-active-box-shadow,
        0 0 0 $btn-focus-width rgba($darkGray, 0.5);
    }
  }
}

.btn-outline-secondary {
  @include button-outline-variant($darkGray);
}

.btn-link {
  border: none;
  &:not(.nav-link) {
    padding: 0;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      &,
      &:focus,
      &.focus {
        box-shadow: none;
      }
    }
  }
}

html,
body,
#app {
  height: 100%;
  font-display: swap;
}

body,
input {
  letter-spacing: 0.2px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

textarea::placeholder,
input::placeholder {
  color: $input-placeholder-color;
}

p {
  font-size: 15px;
  font-weight: 300;
  color: $darkGray;
}

@import './slick-theme.scss';
